/* eslint-disable @typescript-eslint/no-namespace */
import type { FunctionalComponent } from 'preact';

import { useWidgetScript } from 'src/shared/hooks/use-widget-script/use-widget-script.js';

import { widgetScriptUrl } from './block-direct-sales-widget.constants.js';

declare global {
    namespace preact.createElement.JSX {
        interface IntrinsicElements {
            'ds-widget': any;
        }
    }
}

type BlockAttributes = {
    language: string;
    countryCode: string;
    typeformId?: string;
    profession?: string;
    displayMode?: 'column-row' | 'column';
    typUrl?: string;
    analyticLeadSource?: string;
    showSteps?: string;
    warmFunnel?: string;
    warmFunnelBacUrl?: string;
    warmFunnelNoBacUrl?: string;
};

export interface BlockDirectSalesWidgetProps extends BlockAttributes {}

export const BlockDirectSalesWidget: FunctionalComponent<BlockDirectSalesWidgetProps> = (props) => {
    const {
        language,
        countryCode,
        profession,
        typeformId,
        displayMode,
        typUrl,
        analyticLeadSource,
        showSteps,
        warmFunnel,
        warmFunnelBacUrl,
        warmFunnelNoBacUrl,
    } = props;

    useWidgetScript(widgetScriptUrl);

    return (
        <ds-widget
            language={language}
            country-code={countryCode}
            profession={profession}
            typeform-id={typeformId}
            typ-url={typUrl}
            analytic-lead-source={analyticLeadSource}
            show-steps={showSteps}
            display-mode={displayMode}
            warm-funnel={warmFunnel}
            warm-funnel-bac-url={warmFunnelBacUrl}
            warm-funnel-no-bac-url={warmFunnelNoBacUrl}
        ></ds-widget>
    );
};
